.container-header {
  padding-top: 30px;
  position: relative;
  padding-left: 8%;
  padding-bottom: 30px;
  width: 100%;
}

.container-logo {
  height: 10%;
  width: 20%;
  min-width: 200px;
  text-align: center;
  display: inline-block;
  overflow: hidden;
}

.container-logo img {
  width: 100%;
  min-width: 130px;
}
