.container-footer {
  height: 155px;
  width: 100%;
  background: #110e42;
  position: relative;
  bottom: 0;
  padding-left: 8%;
  padding-right: 8%;
  padding-top: 15px;
}

.relative_div {
  position: relative;
}
.logo-footer {
  position: absolute;
  bottom: 100px;
}

.logo-footer img{
  width: 100%;
  max-width: 225px;
}