.containerLogin {
  height: 380px;
  width: 220px;
  position: absolute;
  left: 13%;
  top: 100px;
  z-index: 1;
  background-color: #110E42;
}

.backGroundLogin {
  height: 360px;
  width: 200px;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 2;
  background-color: #fff;
  padding: 20px;
}

.containerForm {
  height: 100%;
  width: 160px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
}

.fieldsDiv {
  height: 30%;
  width: 100%;
  text-align: center;
}

.fieldsDiv iframe, .fieldsDiv div{
    width: 100% !important;
}


.body-container{
    min-height: 500px;
}


/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.99px) {
  .containerLogin {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

}