.content-relative {
  position: relative;
}
.container-content {
  width: 100%;
  padding-top: 25px;
  padding-left: 8%;
  padding-right: 8%;
}

.image-login-container {
  width: 100%;
  height: 450px;
}

.image-login {
  height: 380px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.content-color {
  height: 150px;
  width: 50%;
  position: absolute;
  right: 0;
  bottom: -55px;
  z-index: 1;
  background-color: #110e42;
}

.grid-color {
  height: 100%;
  width: 33.33%;
  padding: 10px;
  padding-top: 15px;
  float: left;
}
.arrow-container {
  width: 50%;
  height: 250px;
}

.content-text-container {
  text-align: center;
  height: 100%;
  width: 74%;
  vertical-align: top;
  padding: 0px;
  float: left;
}
.text-mei {
  color: #989898;
  font-size: 30px;
  max-width: 100%;
  max-height: 100%;
  text-align: justify;
}

.arrow-relative-l,
.arrow-relative-r {
  position: relative;
  height: 100%;
  width: 13%;
  float: left;
}
.arrow-relative-l > div {
  position: absolute;
  bottom: 33%;
}
.arrow-relative-r > div {
  position: absolute;
  top: 27%;
}
/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.99px) {
  .content-color {
    width: 60%;
  }
  .arrow-container {
    width: 100%;
  }
}
/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.99px) {
  .content-color {
    width: 65%;
  }
}

@media (max-width: 910.99px) {
  .content-color {
    height: 380px;
    width: 45%;
    bottom: -25px;
    top: inherit;
  }
  .grid-color {
    height: 33.33%;
    width: 100%;
    float: left;
  }
  .text-mei {
    font-size: 25px;
  }
  .arrow-relative-l > div {
    bottom: 28%;
  }
  .arrow-relative-r > div {
    top: 7%;
  }
}


/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.99px) {
    .text-mei {
        font-size: 20px;
      }
      .arrow-relative-l > div {
        bottom: 40%;
      }
      .arrow-relative-r > div {
        top: 5%;
      }
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.99px) {
  .content-color {
    height: 410px;
    width: 100%;
    bottom: inherit;
    top: inherit;
    margin-left: auto;
    margin-right: auto;
    right: 0;
    left: 0;
  }
  .grid-color {
    height: 33.33%;
    float: left;
  }
  .image-login-container {
    height: 900px;
  }

}
